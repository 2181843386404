import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import {useSessionStorage} from "react-use-storage";
import { createPortal } from 'react-dom';
import {polygon, point, booleanPointInPolygon} from 'turf';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';

import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

import MapBox from './MapBox.js';
import ProductFilter from './ProductFilter.js';
import ProductListing from './ProductListing.js';
// import logo from './logo.svg';
// import './App.css';

const mapboxgl = window.mapboxgl;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFCA17',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const App = ({type, textTemplate, disableLink}) => {
	const textRenderFunc = useMemo(() => {
		if (!textTemplate || (textTemplate.length == 0)) return false;
		return new Function("return " + `function (item) { return ${textTemplate}; }`)();
	}, [textTemplate]);

	const [loading, setLoading] = useState(true);
	const [filters, setFilters] = useState(() => ({
		search: '',
		lan: 'Alla',
		landskap: 'Alla',
		categories: {},
		tags: {}
	}));
	const [data, setData] = useSessionStorage('data_' + type, {
		products: []
	});
	//   const [data, setData] = useState({products: []});
	const [filteredData, setFilteredData] = useState({...data});

	useEffect(() => {
		(async () => {
			let products = data.products.filter(prod => {
				if (filters.search.length > 0)
					return JSON.stringify(prod).toLowerCase().indexOf(filters.search.toLowerCase()) > -1;


				return true;
			});
			// Check if inside region filters
			const landskap = data.map && data.map.landskap && data.map.landskap.features.filter(({properties}) => {
				return properties.landskap == filters.landskap;
			})[0];
			
			const lan = data.map && data.map.lan && data.map.lan.features.filter(({properties}) => {
				return properties.namn == filters.lan;
			})[0];
			products = products.filter((prod) => {
				let pass = true;

				// Check landskap
				if (landskap){
					let landskapCoords = landskap.geometry.coordinates;
					if (typeof landskapCoords[0][0][0] == 'object'){
						landskapCoords = landskapCoords.flat();
					}

					var pt = point(prod.location.geometry.coordinates);
					var poly = polygon(landskapCoords);
					if (!booleanPointInPolygon(pt, poly)){
						pass = false;
					}
				}

				// Check lan
				if (lan){
					let lanCoords = lan.geometry.coordinates;
					/* if (typeof landskapCoords[0][0][0] == 'object'){
						lanCoords = lanCoords.flat();
					} */

					var pt = point(prod.location.geometry.coordinates);
					var poly = polygon(lanCoords);
					if (!booleanPointInPolygon(pt, poly)){
						pass = false;
					}
				}

				return pass;;
			});
			// Check categories and tags filters
			const categories = Object.keys(filters.categories).filter(key => filters.categories[key]);
			const tags = Object.keys(filters.tags).filter(key => filters.tags[key]);
			console.log('categories, tags', categories, tags);
			products = products.filter(prod => {
				let pass = true;
				// Cats
				pass = categories.length ? categories.filter(cat => {
					if (!prod.categories) return false;

					return (prod.categories.map(catObj => catObj.name).indexOf(cat) > -1);
				}).length : pass;
				// Tags
				pass = tags.length ? tags.filter(cat => {
					if (!prod.tags) return false;

					return (prod.tags.map(catObj => catObj.name).indexOf(cat) > -1);
				}).length : pass;
				return pass;
			});
			// Return
			setFilteredData({
				...data,
				products,
				resultCount: products.length
			});
		})();
	}, [data, filters]);

	useLayoutEffect(() => {
		if (data && data.products.length){
			// Already has cached data, so render right away
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		// if (!data){
			axios.get(window.ajaxurl + '?action=product_map_data&type=' + type).then(({data}) => {
				console.log('data', data);
				setData(data);
				setTimeout(() => {
					try{
						document.getElementById('product_map_placeholder').remove();
					} catch (err){}
					setLoading(false);
				}, 10);
			});
		// }
	}, [type]);

	return loading ? (
		<div className="App" style={{textAlign: 'center'}}>
			<CircularProgress />
		</div>
	) : (
		<ThemeProvider theme={theme}>
			<div className="App">
				<ProductFilter data={filteredData} filters={filters} setFilters={setFilters} />

				<h4>Resultat: {filteredData.resultCount}</h4>

				{createPortal(<MapBox data={filteredData} totalMarkersCount={data.products.length} />, document.getElementById('product_map'))}

				{createPortal(<ProductListing filters={filters} setFilters={setFilters} disableLink={disableLink} textRenderFunc={textRenderFunc} products={filteredData.products} />, document.getElementById('product_map_listing'))}
			</div>
		</ThemeProvider>
	);
}

export default App;
