import React, { useRef, useEffect, useMemo, useState } from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import styled from 'styled-components';
// import 'mapbox-gl/dist/mapbox-gl.css';

import useMapMarkers from './mapMarkers.js';

const CounterFunc = ({value, ...props}) => {
	const [currentValue, setCurrentValue] = useState(value);

	useEffect(() => {
		let ref;
		let currentValueHolder = currentValue || 0;
		let loop = () => {
			// Do stuff
			if (Math.round(currentValueHolder) !== value){
				currentValueHolder += (value - currentValueHolder) / 20;
				setCurrentValue(Math.round(currentValueHolder));
			}

			ref = requestAnimationFrame(loop);
		};
		ref = requestAnimationFrame(loop);
		return () => cancelAnimationFrame(loop);
	}, [value]);

	return <h2 {...props}>{currentValue}</h2>;
};

const Counter = styled(CounterFunc)`
	position: absolute;
	top: 25%;
	left: 50%;
	z-index: 1;
	transform: translate(-50%, -50%);
`;

const mapboxgl = window.mapboxgl;

const MapBox = ({data, totalMarkersCount}) => {
	const [map, setMap] = useState(null);
	const products = useMemo(() => {
		return data.products.map((prod) => {
			return {
				'type': 'Feature',
				'properties': {
					'description': '<strong>' + prod.post_title + '</strong><br/>' + prod.post_content
				},
				'geometry': {
					'type': 'Point',
					'coordinates': prod.location.geometry.coordinates
				}
			};
		});
	}, [data]);

	console.log(products);

	useMapMarkers({map, markers: products, totalMarkersCount});
	
	useEffect(() => {
		mapboxgl.accessToken = 'pk.eyJ1IjoiYWxiaW5ob2xtbHVuZCIsImEiOiJja2tnMHp2cnUweXkwMnVtbnV4eGtrcnlvIn0.oS1cQ0NkWWtO4CcjDc9CNw';

		const map = new mapboxgl.Map({
			container: 'product_map_mapbox',
			style: 'mapbox://styles/mapbox/light-v10',
			center: [16.619505665120897, 62.48913215285231],
			zoom: 4.5,
			minZoom: 4.5,
			// pitch: 50
			// maxBounds: bounds
		});
		setMap(map);
	}, []);

	useEffect(() => {
		return;
		var markers = [];
		data.products.forEach(prod => {
			// create a HTML element for each feature
			var el = document.createElement('div');
			el.className = 'marker';

			var marker = new mapboxgl.Marker(el)
				.setLngLat(prod.location.geometry.coordinates)
				.addTo(map);
			markers.push(marker);
		});
		return () => {
			markers.forEach(m => m.remove());
		};
	}, [data, map]);

	return (
		<React.Fragment>
			<div
				id="product_map_mapbox"
				style={{
					width: '100%', 
					height: '80vh',
					boxShadow: '5px 5px 5px 0 rgb(0 0 0 / 10%)'
				}}
			/>
			<Counter value={data.products.length} />
		</React.Fragment>
	);
};

export default MapBox;