import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence as AnimatePresence, usePresence } from "framer-motion";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';

import translate from './translate.js';

//const AnimatePresence = styled.div``;

/*** 
** Create styled components here
***/

const ProductItemFunc = ({children, ...props}) => {
	const [isPresent, safeToRemove] = usePresence()

	useEffect(() => {
		// !isPresent && setTimeout(safeToRemove, 5000)
		if (!isPresent) safeToRemove();
	}, [isPresent])

	return (
		<motion.div {...props}>
			{children}
		</motion.div>
	);
};

const ProductItem = styled(ProductItemFunc)`
	display: flex;
	position: relative;
	padding: 0;
	background-color: #FDFDFD;
	margin-bottom: 20px;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);

	.product__image{
		background-size: cover;
		background-position: center;
		width: 40%;
		padding-bottom: 10%;
		flex: 1 1 40%;
	}
	.product__content{
		flex: 1 1 60%;
		padding: 40px 60px;
	}
	.product__info{
		display: flex;
	}
	.product__left{
		width: 500px;
		max-width: 100%;
		padding-right: 40px;
	}
	.product__right{

	}

	.elementor-button{
		background-color: var( --e-global-color-c90ec84 );
		color: #fff;
	}

	@media (max-width: 920px){
		flex-direction: column;
		.product__image{
			width: 100%;
			padding-bottom: 50%;
		}
	}
`;

const ProductListing = ({filters, setFilters, disableLink, textRenderFunc, products, ...props}) => {
	return (
		<AnimatePresence initial={false}>
			{products ? products.map((prod, index) => {
				let showOnMap = (filters.search == prod.post_title) ? (
					<a onClick={(e) => {
						e.preventDefault();
						setFilters({...filters, search: ''});
					}} className="elementor-button-link elementor-button " style={{display: 'inline-flex', backgroundColor: '#ececec', color: '#000'}} href="#">
						<IoCloseSharp style={{marginRight: 10}} /> 
						{translate('Visa alla')}
					</a>
				) : (
					<a onClick={(e) => {
						e.preventDefault();
						setFilters({...filters, search: prod.post_title});

						var $ = window.jQuery;
						var headerHeight = document.querySelector('header').getBoundingClientRect().height;
						window.scrollTo(0, $("#map-holder").offset().top - headerHeight);
					}} className="elementor-button-link elementor-button " style={{display: 'inline-flex', backgroundColor: '#ececec', color: '#000'}} href="#">
						<FaMapMarkerAlt style={{marginRight: 10}} /> 
						{translate('Visa på kartan')}
					</a>
				);	
				return <ProductItem 
					key={prod.ID}
					layout
					initial={{ opacity: 0, x: -50}}
					animate={{ opacity: 1, x: 0, transition: { duration: 0.1 /*duration: Math.min(0.2 * (1 + (index * 0.5)), 1) */} }}
					exit={{ opacity: 0, x: 50, transition: { duration: 0.1 /*duration: Math.min(0.2 * (1 + (index * 0.5)), 1) */} }}
				>
					{(prod.external_link && !disableLink) ? (
						<a className="product__content" target="_blank" href={prod.external_link} style={{color: '#000'}}>
							<h2>{prod.post_title}</h2>
							<p>
								{textRenderFunc ? textRenderFunc(prod) : prod.post_excerpt}
							</p>
							<span style={{marginRight: 10}} className="elementor-button-link elementor-button ">{translate('Läs mer')}</span>
							{showOnMap}
						</a>
					) : (
						<React.Fragment>
							{prod.image && (
								<div className="product__image" style={{backgroundImage: `url(${prod.image})`}} />
							)}
							<div className="product__content">
								<h2>{prod.post_title}</h2>
								<div className="product__info">
									<div className="product__left">
										<p>
											{textRenderFunc ? textRenderFunc(prod) : prod.post_excerpt}
										</p>
										{!disableLink && (
											<a style={{marginRight: 10}} className="elementor-button-link elementor-button " href={prod.link}>{translate('Läs mer')}</a>
										)}
										{showOnMap}
									</div>
									{!disableLink && prod.categories && prod.categories.length && (
										<div className="product__right">
											<p><strong>{translate('Här kan du göra:')}</strong></p>
											<p>
												{prod.categories.map(({name}) => (
													<span>{name}<br/></span>
												))}
											</p>
										</div>
									)}
								</div>
							</div>
						</React.Fragment>
					)}
				</ProductItem>;
			}) : (
				<h2>Det finns inga produkter som matchar sökningen.</h2>
			)}
		</AnimatePresence>
	);
};

export {ProductListing};
export default ProductListing;
