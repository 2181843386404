import React, { useEffect, useState } from 'react';
const mapboxgl = window.mapboxgl;

const useMapMarkers = ({map, markers, totalMarkersCount}) => {
	useEffect(() => {
		if (!map || !markers) return;

		if (map.getSource('places')){
			console.log('Update markers');
			map.getSource('places').setData({
				'type': 'FeatureCollection',
				'features': markers
			});

			if (markers.length == 1){
				map.flyTo({
					center: markers[0].geometry.coordinates,
					zoom: 8
				});
			} else if (markers.length == 0){
				map.flyTo({
					center: [16.619505665120897, 62.48913215285231],
					zoom: 4.5,
				});
			} else if (markers.length > 1){
				var bounds = new mapboxgl.LngLatBounds();
				markers.forEach(function(feature) {
				    bounds.extend(feature.geometry.coordinates);
				});
				map.fitBounds(bounds, {
					padding: 100
				});
			}
		} else {
			map.on('load', function() {
				map.loadImage(
					'/wp-content/plugins/tbst-map-product-search/marker3.png',
					// Add an image to use as a custom marker
					function(error, image) {
						if (error) throw error;
						map.addImage('custom-marker', image);

						if (!map.getSource('places')){

							map.addSource('places', {
								'type': 'geojson',
								'data': {
									'type': 'FeatureCollection',
									'features': markers
								}
							});

							// Add a layer showing the places.
							map.addLayer({
								'id': 'places',
								'type': 'symbol',
								'source': 'places',
								'layout': {
									'icon-image': 'custom-marker',
									'icon-allow-overlap': true
								}
							});

							// Update
							/* setInterval(() => {
								map.getSource('places').setData({
									'type': 'FeatureCollection',
									'features': markers.filter(prod => {
										return Math.random() > 0.5;
									})
								});
							}, 1000/15);*/
						}
					}
				);

				// Create a popup, but don't add it to the map yet.
				var popup = new mapboxgl.Popup({
					closeButton: false,
					closeOnClick: false
				});

				map.on('mouseenter', 'places', function(e) {
					// Change the cursor style as a UI indicator.
					map.getCanvas().style.cursor = 'pointer';

					var coordinates = e.features[0].geometry.coordinates.slice();
					var description = e.features[0].properties.description;

					// Ensure that if the map is zoomed out such that multiple
					// copies of the feature are visible, the popup appears
					// over the copy being pointed to.
					while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
						coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
					}

					// Populate the popup and set its coordinates
					// based on the feature found.
					popup.setLngLat(coordinates).setHTML(description).addTo(map);
				});

				map.on('mouseleave', 'places', function() {
					map.getCanvas().style.cursor = '';
					popup.remove();
				});
			});
		}
	}, [map, markers]);
};

export default useMapMarkers;