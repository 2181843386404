import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import SearchIcon from '@material-ui/icons/Search';

import translate from './translate.js';

Object.defineProperty(Array.prototype, 'chunk_inefficient', {
  value: function(chunkSize) {
    var array = this;
    return [].concat.apply([],
      array.map(function(elem, i) {
        return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
      })
    );
  }
});

const Container = styled.div`
	input{
		border: 0;
		padding-left: 0;
		padding-right: 0;
	}
`;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
  },
  textField: {
    margin: theme.spacing(3),
    marginLeft: 0,
    marginBottom: 0
	// backgroundColor: '#FEFCFD',
	// boxShadow: '0 0 0 2px #f0f0f0'
  },
  formControl: {
  	display: 'flex',
  	flexDirection: 'row',
  	flexWrap: 'wrap',
    margin: theme.spacing(3),
    marginLeft: 0,
    width: '100%'
  },
}));

const ProductFilter = ({data, filters, setFilters}) => {
  const classes = useStyles();

  const debouncedSearch = useDebouncedCallback((value) => {
    setFilters({...filters, search: value});
  }, 200);

  const [searchState, setSearchState] = React.useState(filters.search || '');

  useEffect(() => {
    // debouncedSearch.callback(searchState);
    setFilters({...filters, search: searchState});
  }, [searchState]);

	return (
		<Container>
			<TextField 
				className={classes.textField}
				fullWidth 
				label={translate('Vart vill du?')} 
				defaultValue="" 
				placeholder={translate('Skriv in ett område eller en aktivitet')} 
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
				value={searchState}
				onChange={(e) => setSearchState(e.target.value)}
			/>

			<div className={classes.root}>


      <div style={{display: 'flex'}}>
	      <FormControl component="fieldset" className={classes.formControl}>
		        <InputLabel>{translate('Välj landskap')}</InputLabel>
		        <Select
		        	style={{width: '100%'}}
		          value={filters.landskap}
		          onChange={(e) => setFilters({...filters, lan: 'Alla', landskap: e.target.value})}
		        >
              <MenuItem value={'Alla'}>{translate('Alla')}</MenuItem>
              {data.map && data.map.landskap && data.map.landskap.features && data.map.landskap.features.map(({properties}) => (
                <MenuItem key={properties.landskap} value={properties.landskap}>{properties.landskap}</MenuItem>
              ))}
		        </Select>
	      </FormControl>
	      <FormControl component="fieldset" className={classes.formControl}>
		        <InputLabel>{translate('Välj län')}</InputLabel>
		        <Select
		        	style={{width: '100%'}}
		          value={filters.lan}
		          onChange={(e) => setFilters({...filters, landskap: 'Alla', lan: e.target.value})}
		        >
              <MenuItem value={'Alla'}>{translate('Alla')}</MenuItem>
              {data.map && data.map.lan && data.map.lan.features && data.map.lan.features.map(({properties}) => (
                <MenuItem key={properties.namn} value={properties.namn}>{properties.namn}</MenuItem>
              ))}
		        </Select>
	      </FormControl>
	     </div>

       {data.categories && data.categories.length ? (
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{translate('Vad vill du göra?')}</FormLabel>
            {data.categories && data.categories.chunk_inefficient(3).map((arr, index) => (
              <FormGroup key={index}>
                {arr.map((tag) => (
                  <FormControlLabel
                    key={tag}
                    control={<Checkbox color="primary" checked={filters.categories[tag]} onChange={() => setFilters({...filters, categories: {...filters.categories, [tag]: !filters.categories[tag]}})} />}
                    label={tag}
                  />
                ))}
              </FormGroup>
          ))}
        </FormControl>
      ) : null}

      {data.tags && data.tags.length ? (
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">{translate('Visa resultat som')}</FormLabel>
          {data.tags && data.tags.chunk_inefficient(3).map((arr, index) => (
            <FormGroup key={index}>
              {arr.map((tag) => (
                <FormControlLabel
                  key={tag}
                  control={<Checkbox color="primary" checked={filters.tags[tag]} onChange={() => setFilters({...filters, tags: {...filters.tags, [tag]: !filters.tags[tag]}})} />}
                  label={tag}
                />
              ))}
            </FormGroup>
          ))}
        </FormControl>
      ) : null}
			</div>
		</Container>
	);
};

export {ProductFilter};
export default ProductFilter;
