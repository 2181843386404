import './publicPath';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker?inline=fallback').default;

// Wrap the rendering in a function:
const render = () => {
	if (window.location.href.indexOf('action=') > -1 || window.location.href.indexOf('elementor-preview') > -1) return;

	console.log(document.getElementById('product_map_filters'));
	if (!document.getElementById('product_map_filters')) return;
	let type = document.getElementById('product_map_filters').dataset.type;
	let textTemplate = document.getElementById('product_map_filters').dataset.textTemplate;
	let disableLink = document.getElementById('product_map_filters').dataset.disableLink;
	if (disableLink && disableLink.length){
		disableLink = disableLink == 'true' ? true : false;
		console.log('disableLink', disableLink);
	}
	ReactDOM.render(<App disableLink={disableLink} textTemplate={textTemplate} type={type} />, document.getElementById('product_map_filters'));
	// console.log(ReactDOM);
	// ReactDOM.unstable_createRoot(document.getElementById('product_map_filters')).render(<App />);
};

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
